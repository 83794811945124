import React from 'react';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

import FeatureContainer from '../../components/FeatureContainer';
import TrustedBy from '../../components/TrustedBy';
import { signUp } from '../../utils/contentProvider/function';
import 'styles/usecases.scss';
import TestimonialsSlider from '../../components/TestimonialsSlider/TestimonialsSlider';
import NewFaq from '../../components/NewFaq/NewFaq';

const qualityAssuranceFaq = [
  {
    q: 'Can I track the resolved comments while conducting quality tests?',
    a: 'Yes, you can track all comments in your Activity panel directly inside the webpage, image or PDF itself on ruttl.',
  },
  {
    q: 'I noticed ruttl integrates with Zapier. How can I learn more about that?',
    a: (
      <>
        You can find out more about the same on our
        <Link to="/integrations/">&nbsp;Integrations&nbsp;</Link>
        page, and follow the simple steps in our
        <a
          href="https://youtu.be/vrff3vYs93I"
          target="_blank"
          rel="noopener noreferrer">
          &nbsp;quick video&nbsp;
        </a>
        to integrate with Zapier.
      </>
    ),
  },
  {
    q: 'How to use ruttl for QA & Bug Reporting?',
    a: 'Add your website to ruttl, add collaborators and comment on the areas, which seem to be buggy or out of context.',
  },
  {
    q: 'Is it possible to share multiple attachments in comments?',
    a: 'Yes, you can add multiple attachments to your comments on ruttl',
  },
].map(({ q, a }) => ({
  q,
  a,
  uuid: nanoid(),
}));
const QualityAssurance = ({ location }) => (
  <Layout location={location}>
    {({ toggleSignup }) => (
      <>
        <Meta
          title="Ruttl - The #1 Feedback Tool to perform quality assurance on websites 🔥"
          description="Steamline your quality assurance process with Ruttl by simplifying your feedback collection!"
          url="https://ruttl.com/usecases/quality-assurance"
        />
        <main className="usecases-styled-main">
          <section className="features-hero custom-padding">
            <div className="container">
              <h1>Conduct QA and manual testing with ease</h1>
              <p>Share video recordings, comments, and suggestions</p>
              <a href="https://web.ruttl.com" className="button">
                Get Started for Free
              </a>
            </div>
            <TrustedBy />
          </section>
          <div className="section features-main" id="features">
            <div className="container">
              <h2 className="center mt-40">Features</h2>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <img
                        src="/assets/img/leave-comments-website.jpg"
                        alt="illustration of leaving comment on live website"
                        title="illustration of leaving comment on live website"
                      />
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-50px)' }}>
                      <h2
                        className="feature-head"
                        style={{ maxWidth: '420px' }}>
                        Respond to issues faster
                      </h2>
                      <p>
                        In case of a bug or a design issue, quickly make the
                        edits on live websites and recommend suggestions with
                        the help of text and video comments.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Edit Live Websites
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <video
                        src="/assets/img/guest-commenting.mp4"
                        autoPlay
                        muted
                        loop
                        playsInline></video>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(-30px)' }}>
                      <h2 className="feature-head">
                        Allow customer commenting
                      </h2>
                      <p>
                        Without signing up or logging in to Ruttl, your
                        customers can leave comments, suggestions, and feedback
                        on certain areas of your software.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Try Client Commenting
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/comments-resolved.png"
                          alt="illustration of comments resolved after done"
                          title="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2 className="feature-head">
                        Assign feedback to your team
                      </h2>
                      <p>
                        Invite your team and assign feedback comments to them
                        and resolve issues faster leading to customer
                        satisfaction.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Start Collaborating
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
              <FeatureContainer>
                <div className="feature-set-single">
                  <div className="flex flex-wrap align-items-center ">
                    <div className="feature-img">
                      <div className="feature-img-bg">
                        <img
                          src="/assets/img/kan-ban-style-cards.png"
                          alt="illustration of comments resolved after done"
                          title="illustration of comments resolved after done"
                        />
                      </div>
                    </div>
                    <div
                      className="feature-txt"
                      style={{ transform: ' translateY(0px)' }}>
                      <h2 className="feature-head">
                        Get notified of new issues
                      </h2>
                      <p>
                        Never miss out on any important updates by getting
                        notified of new comments and suggestions and integrating
                        Ruttl with Slack, Trello, and Asana.
                      </p>
                      <button
                        type="button"
                        onClick={() => signUp(toggleSignup)}
                        className="button">
                        Check Out The Integrations
                      </button>
                    </div>
                  </div>
                </div>
              </FeatureContainer>
            </div>
          </div>
          <TestimonialsSlider />
          <NewFaq data={qualityAssuranceFaq} />
        </main>
      </>
    )}
  </Layout>
);

QualityAssurance.propTypes = {
  location: PropTypes.object,
};

export default QualityAssurance;
